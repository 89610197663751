const ClientsActionsTypes = {
    ADD_CLIENT_START : 'ADD_CLIENT_START',
    ADD_CLIENT_SUCCESS : 'ADD_CLIENT_SUCCESS',
    ADD_CLIENT_FAILURE : 'ADD_CLIENT_FAILURE',

    SEND_MESSAGE_START : 'SEND_MESSAGE_START',
    SEND_MESSAGE_SUCCESS : 'SEND_MESSAGE_SUCCESS',
    SEND_MESSAGE_FAILURE : 'SEND_MESSAGE_FAILURE',
};

export default ClientsActionsTypes;
