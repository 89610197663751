const ProductsActionsTypes = {
    FETCH_PRODUCTS_START : 'FETCH_PRODUCTS_START',
    FETCH_PRODUCTS_SUCCESS : 'FETCH_PRODUCTS_SUCCESS',
    FETCH_PRODUCTS_FAILURE : 'FETCH_PRODUCTS_FAILURE',
    FETCH_ALL_PRODUCTS_START : 'FETCH_ALL_PRODUCTS_START',
    FETCH_ALL_PRODUCTS_SUCCESS : 'FETCH_ALL_PRODUCTS_SUCCESS',
    FETCH_ALL_PRODUCTS_FAILURE : 'FETCH_ALL_PRODUCTS_FAILURE',
    GET_PRODUCT_START : 'GET_PRODUCT_START',
    GET_PRODUCT_SUCCESS : 'GET_PRODUCT_SUCCESS',
    GET_PRODUCT_FAILURE : 'GET_PRODUCT_FAILURE',
};

export default ProductsActionsTypes;
