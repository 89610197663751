const CategoriesActionsTypes = {
    FETCH_CATEGORIES_START : 'FETCH_CATEGORIES_START',
    FETCH_CATEGORIES_SUCCESS : 'FETCH_CATEGORIES_SUCCESS',
    FETCH_CATEGORIES_FAILURE : 'FETCH_CATEGORIES_FAILURE',
    GET_CATEGORY_START : 'GET_CATEGORY_START',
    GET_CATEGORY_SUCCESS : 'GET_CATEGORY_SUCCESS',
    GET_CATEGORY_FAILURE : 'GET_CATEGORY_FAILURE',
};

export default CategoriesActionsTypes;
