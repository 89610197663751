import React from 'react';
import './loader.style.scss'


const Loader = () => {
    return (
        <div className="loader"></div>
    )
}

export default Loader;