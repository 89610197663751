const SubcategoriesActionsTypes = {
    FETCH_SUBCATEGORIES_START : 'FETCH_SUBCATEGORIES_START',
    FETCH_SUBCATEGORIES_SUCCESS : 'FETCH_SUBCATEGORIES_SUCCESS',
    FETCH_SUBCATEGORIES_FAILURE : 'FETCH_SUBCATEGORIES_FAILURE',
    GET_SUBCATEGORY_START : 'GET_SUBCATEGORY_START',
    GET_SUBCATEGORY_SUCCESS : 'GET_SUBCATEGORY_SUCCESS',
    GET_SUBCATEGORY_FAILURE : 'GET_SUBCATEGORY_FAILURE',
};

export default SubcategoriesActionsTypes;
